@class-prefix-index-bar: ~"bo-adm-index-bar";

.@{class-prefix-index-bar} {
  --color: var(--adm-color-text);
  overflow: hidden;

  height: 100%;
  position: relative;
  background-color: var(--adm-color-white);
  --sticky-offset-top: 0;

  &-body {
    overflow: scroll;
    height: 100%;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-grid {
    display: inline-flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
  }
  &-anchor {
    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 35px;
      padding: 0 12px;
      color: var(--adm-color-weak);
      font-size: 13px;
      background-color: #f5f5f5;
    }
  }

  &-tab-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    padding: 12px 8px;
    height: 45px;
  }
  &-tab-wrapper {
    padding: 0px;
  }

  &-tab {
    white-space: nowrap;
    padding: 0px 8px 0px;
    width: min-content;
    margin: 0 auto;
    font-size: var(--title-font-size);
    position: relative;
    cursor: pointer;
  }
  &-tab-active {
    color: #fff;
    // background-color: var(--adm-color-primary);
    // border-radius: 50%;
    // color: #f5f5f5;
    width: 100%;
    text-align: center;
    background-color: var(--adm-color-primary);
  }
  &-sidebar {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 910;
    overflow: visible;
    color: var(--adm-color-weak);
    font-size: 12px;
    user-select: none;
    touch-action: none;

    &-bubble {
      position: absolute;
      top: 50%;
      right: 42px;
      width: 47px;
      height: 47px;
      color: var(--adm-color-white);
      font-size: 25px;
      line-height: 47px;
      text-align: center;
      background: var(--adm-color-light);
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &-row {
      cursor: pointer;
      width: auto;
      text-align: right;
      position: relative;
      padding: 0 12px;
      > * {
        pointer-events: none;
      }
    }

    &-item {
      display: inline-flex;
      width: 16px;
      height: 16px;
      line-height: 16px;
      justify-content: center;
      align-items: center;
    }

    &-tab-active {
      color: #fff;
      // background-color: var(--adm-color-primary);
      // border-radius: 50%;
      // color: #f5f5f5;
      width: 100%;
      text-align: center;
      background-color: var(--adm-color-primary);
    }

    &-interacting {
      width: 100%;
    }
  }

  &-sticky {
    .@{class-prefix-index-bar}-anchor-title {
      position: sticky;
      z-index: 900;
      top: var(--sticky-offset-top);
      left: 0;
    }
  }
}

@primary-color: #8F001B;@root-entry-name: default;