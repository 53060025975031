
@class-prefix-mall-home: ~'mall-home';
.@{class-prefix-mall-home} {
  height: 100%;
  width: 100%;
  bottom: calc(51px + env(safe-area-inset-bottom));
  &-search-bar-container {
    .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
      --placeholder-color: var(---placeholder-color);
      --font-size: 17px;
    }
      position: fixed;
      display: block;
      margin: 0;
      width: 100%;
      top: 0px;
      padding: 5px 5px;
      height: 42px;
      max-height: 44px;
      // background: #faad14;
      font-size: 17px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  &-container{
    .content {
      height: 160px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      user-select: none;
    }
    // background-color: aqua;
    width: 100%;
    height: 100%;
    top: 42px;
    right: 0px;
    padding-bottom: calc(51px + 42px + 10px + env(safe-area-inset-bottom));
    overflow-y: scroll;
    position: fixed;
  }
  // &-cate {
  //   padding: 0 12px;
  // }
}



@primary-color: #8F001B;@root-entry-name: default;