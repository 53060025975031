@class-prefix-mall-login: ~'mall-login';

.@{class-prefix-mall-login} {
    &-container {
        height: 100vh;
        width: 100%;
        // background-color: rgb(245, 247, 250);
        display: flex;
        flex-direction: column;
        .adm-tabs-header {
            border-bottom: 0px;
        }

    }
    &-logo {
        box-sizing: border-box;
        width: 112px;
        height: 112px;
        margin: 50px auto 50px;
        padding: 6px;
    }
}

@primary-color: #8F001B;@root-entry-name: default;