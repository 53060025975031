.profile-block {
    // border: solid 1px #999999;
    background: #f5f5f5;
    text-align: center;
    // color: #999999;
    padding: 40px 0;
    height: 150px;
  }

  @class-prefix-account: ~'account';
.@{class-prefix-account} {
    &-container {
        width: 100%;
        padding: 6.5px;
        background-color: #f5f5f7;
    }
    &-mobile-item {
      text-align: center;
      padding: 0px 6.5px;
    }
    
    &-mobile-icon {
    //   border: solid 1px #999999;
    //   border-radius: 8px;
      font-size: 36px;
    }
    
    &-mobile-label {
      font-size: 12px;
      word-wrap: break-word;
      padding-top: 2px;
    }
    &-list {
      margin: 0px;
    }
}
@primary-color: #8F001B;@root-entry-name: default;