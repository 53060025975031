@class-prefix-collapse: ~'adm-collapse';

.@{class-prefix-collapse} {
  &-panel-header {
    & .adm-list-item-content-main {
      padding: 0;
    }
  }
  &-arrow {
    transform: rotate(0deg);
    &-active {
      transform: rotate(-180deg);
    }
    transition: all ease 0.3s;
  }
  &-panel-content {
    font-size: var(--adm-font-size-main);
    color: var(--adm-color-weak);
    overflow: hidden;
    & .adm-list-item-content-main {
      padding: 3px 3px;
    }
  }
}

@primary-color: #8F001B;@root-entry-name: default;