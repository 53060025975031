@class-prefix-order-history: ~"mall-order-history";
.@{class-prefix-order-history} {

  &-background {
    height: 100%;
    width: 100%;
    padding-bottom: calc(51px + env(safe-area-inset-bottom));
    background: rgb(255, 255, 255);
  }

}

.contentFull {
  // height: 100vh;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  user-select: none;
}
@primary-color: #8F001B;@root-entry-name: default;