.detail-list-amount {
    color:black;
    // font-size:12px; 
    font-weight:bold;
    text-align:end;
}

.detail-list-title{
    font-size:16px; 
    font-weight:bold;
}

.detail-list-summary{
    font-size:16px;
    text-align: end;
}
@class-prefix-order-detail-list: ~'order-detail-list';
.@{class-prefix-order-detail-list} {
    &-container {
        // margin-left: 96px;
        width: 100%;
        height: auto;
        padding: 0px;
        position: fixed;
        top: 45px;
        bottom: calc(51px + env(safe-area-inset-bottom));
        right: 0px;
        overflow-y: scroll;
        padding: 6.5px;
        background-color: #f5f5f7
    }
}
@primary-color: #8F001B;@root-entry-name: default;