@class-prefix-cart-list: ~'cart-list';
.@{class-prefix-cart-list} {
    &-container {
        // // margin-left: 96px;
        width: 100%;
        height: 100%;
        // // padding: 0px;
        // // position: fixed;
        // // top: 0px;
        padding-bottom: calc(51px + env(safe-area-inset-bottom));
        // // right: 0px;
        // background-color: aqua;
        position: fixed;
        overflow-y: scroll;
    }
}
@primary-color: #8F001B;@root-entry-name: default;