@class-prefix-mall-qrcode: ~'mall-qrcode';

.@{class-prefix-mall-qrcode} {
    --color: var(--adm-color-text);
    --image-height: var(--adm-error-block-image-height,100px);
    --image-height-full-page: var(--adm-error-block-image-height-full-page,200px);
    --image-width: var(--adm-error-block-image-width,auto);
    --image-width-full-page: var(--adm-error-block-image-width-full-page,auto);
    box-sizing: border-box;
    text-align: center;
    &-full-page {
        padding-top: calc(50vh - var(--image-height-full-page));
    }
    // &-logo {
    //     max-width: 100%;
    //     .adm-image {
    //         margin: auto;
    //     }
    // }
    &-image {
        height: var(--image-height-full-page);
        width: var(--image-width-full-page);
        max-width: 100%;
    }
    &-description {
        margin-top: 20px;
        font-size: var(--adm-font-size-main);
        color: #999;
        line-height: 1.4;
        &-title {
            font-size: 20px;
            color: var(--adm-color-text);
        }
        &-subtitle {
            margin-top: 8px;
            font-size: 14px;
            color: var(--adm-color-text);
        }
        &-content {
            margin-top: 8px;
        }
    }
}

@primary-color: #8F001B;@root-entry-name: default;