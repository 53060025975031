@class-prefix-itemCard: ~'itemCard';
.@{class-prefix-itemCard} {
    &-container{
        width:100px;
        margin: auto;
    }
    &-action{
        float: right;
        margin: 5px 0;
    }
}

@class-prefix-trending: ~'trending';
.@{class-prefix-trending} {
    width: 100%;
    height: 100%;
    padding-bottom: calc(51px + env(safe-area-inset-bottom));
    &-search-bar-container {
        padding: 5px 5px;
        background: #ffffff;
        display: block;
        align-items: center;
        position: sticky;
        z-index: 999999;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 42px;
        max-height: 44px;
        // background: #1890ff;
        // font-size: 17px;
        .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
          --placeholder-color: var(---placeholder-color);
          --font-size: 17px;
        }
      }
    &-action{
        float: right;
        margin: 5px 0;
    }
    .adm-list {
        height: 100%;

    }
    
    .adm-list-body {
        height: -moz-calc(100% - (42px + 33px));
        height: -webkit-calc(100% - (42px + 33px));
        height: calc(100% - (42px + 33px));
    }
    
    .adm-list-body-inner {
        // height: calc(100% - 42px - 51px - env(safe-area-inset-bottom));
        height: 100%;
    }
}


@primary-color: #8F001B;@root-entry-name: default;