
.mall {
    height: 100vh;
    margin: 0;
    background-color:#6363d6;
    position: fixed;
  }

.mall-content {
  // background-color: rgb(135, 243, 11);
  height: 100%;
  width: 100%;
  // touch-action: none;
  position: fixed;
  overflow: hidden;
}
.mall-footer {
    // background: #6aa0c7;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    align-items: center;
    width: 100%;
    padding: 0 24px;
    line-height: 44px;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
    transition: width .3s cubic-bezier(.645,.045,.355,1);
  }

  .adm-input-element {
    font-size:16px;
  }

@primary-color: #8F001B;@root-entry-name: default;