@class-prefix-mall-search: ~'mall-search';

.@{class-prefix-mall-search} {
    &-header {
      padding: 5px 5px;
      background: #ffffff;
      display: flex;
      align-items: center;
      position: sticky;
      z-index: 999999;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 42px;
      max-height: 44px;
      background: #faad14;
      // font-size: 17px;
      .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
        --placeholder-color: var(---placeholder-color);
        --font-size: 17px;
      }
      .left {
        flex: auto;
      }
      .right {
        flex: none;
        padding-left: 12px;
      }
    }
}
@primary-color: #8F001B;@root-entry-name: default;