@class-prefix-goods-list: ~'goods-list';
@class-prefix-sku-list: ~'sku-list';
@class-prefix-vlist: ~'vlist';

.@{class-prefix-goods-list} {
    &-product-sku-name {
        color: black;
        margin-bottom: 2px;
        
    }
    &-product-sku-name-red {
        font-size: 13px;
        color: #8F001B;
    }
    &-product-sku-spec{
        color: black;
        font-size: 14px;
      };
      &-product-sku-unit{
        color: black;
        font-size: 13px;
        text-align: center
      };

    
    &-product-spu-name {
        font-size: 15px;
        text-overflow: "…";
        white-space: nowrap; 
    }
    &-product-spu-name-red {
        font-size: 15px;
        color: #8F001B;
    }
    &-spu-spec{
        font-size: 14px;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        min-width: 0px;
      };

    &-price-container{
        align-items: flex-end;
        justify-content: flex-start;
        -webkit-box-align: end;
        -webkit-box-pack: start;
        border: 0px solid black;
        box-sizing: border-box;
        display: flex;
    }
    &-currency-symbol{
        color: rgb(250, 79, 11); 
        font-size: 13px; 
        margin-bottom: -2px;
      }
    &-price{
        color: rgb(250, 79, 11); 
        font-size: 14px; 
        margin-bottom: -2px;
      }
    &-price-red{
        color: #8F001B; 
        font-size: 16px; 
        margin-bottom: -2px;
      }
    &-original-price-container{
        justify-content: flex-end; 
        margin-left: 3px; 
        -webkit-box-pack: end;
    }
    &-original-price{
        color: rgb(153, 153, 153); 
        font-size: 12px; 
        text-decoration-line: line-through;
    }
    &-dropdown{
        display: flex;
        height: 100%;
    }
    &-list-item {
        display: block;
        // padding-left: 6px;
        .adm-list-item-content {
          padding: 5px 0;
        }
        & .adm-list-item-content-main {
          padding: 0;
        }
    }
    &-search-bar-container {
      .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
        --placeholder-color: var(---placeholder-color);
        --font-size: 17px;
      }
    
        display: block;
        margin: 0;
        width: 100%;
        padding: 5px 5px;
        height: 42px;
        max-height: 44px;
        // background: #faad14;
        font-size: 17px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    &-container {
      background-color: #fff;
      margin-left: 86px;
      // width: auto;
      width:-webkit-fill-available;
      height: auto;
      padding: 0px;
      position: fixed;
      top: 81px;
      bottom: calc(51px + env(safe-area-inset-bottom));
      right: 0px;
      overflow-y: scroll;
  }
  &-sidebar-container{
    background-color: #f5f5f5;
    width: auto;
    padding: 0px;
    position: fixed;
    top: 81px;
    left: 0;
    bottom: calc(51px + env(safe-area-inset-bottom));
    max-width: 86px;
    min-width: 86px;
    width: 86px;
    height: auto;
    overflow-y: scroll;
    overflow: hidden;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    border-right: 0;
    .adm-side-bar{
      width: auto;
    }
    
}
};

.@{class-prefix-sku-list} {
  &-header {
    padding: 5px 5px;
    background: #ffffff;
    display: flex;
    align-items: center;
    position: sticky;
    // z-index: 999999;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 42px;
    max-height: 44px;
    // background: #faad14;
    // font-size: 17px;
    .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
      --placeholder-color: var(---placeholder-color);
      --font-size: 17px;
    }
    .left {
      flex: auto;
    }
    .right {
      flex: none;
      padding-left: 12px;
    }
  }
  &-placeholder {
    padding-top: 30vh;
    text-align: center;
    color: var(--adm-color-weak);
    .loadingWrapper {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
  &-container {
    background-color: #fff;
    // margin-left: 86px;
    // width: auto;
    width:-webkit-fill-available;
    height: auto;
    padding: 0px;
    position: fixed;
    top: 42px;
    bottom: calc(51px + env(safe-area-inset-bottom));
    right: 0px;
    overflow-y: scroll;
  }
  
};

.@{class-prefix-vlist} {
  &-anchor-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 35px;
    padding: 0 12px;
    color: var(--adm-color-weak);
    font-size: 13px;
    background-color: #f5f5f5;
  }
  &-list-item {
    display: block;
    // padding-left: 6px;
    .adm-list-item-content {
      padding: 5px 0;
      .adm-image-tip {
        background-color: #ffffff;
      }
    }
    & .adm-list-item-content-main {
      padding: 0;
      margin-right: 4px;
    }
  }
  &-product-spu-name {
    font-size: 15px;
    text-overflow: "…";
    white-space: nowrap; 
  }
  &-spu-spec{
    font-size: 14px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    min-width: 0px;
  };
  &-price{
    display: flex;
    color: rgb(250, 79, 11); 
    font-size: 14px; 
    margin-bottom: -2px;
  }
  &-search-bar-container {
    .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
      --placeholder-color: var(---placeholder-color);
      --font-size: 17px;
    }
  
      display: block;
      margin: 0;
      width: 100%;
      padding: 5px 5px;
      height: 42px;
      max-height: 44px;
      font-size: 17px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  &-sidebar-container{
    background-color: #f5f5f5;
    width: auto;
    padding: 0px;
    position: fixed;
    top: 81px;
    left: 0;
    bottom: calc(51px + env(safe-area-inset-bottom));
    max-width: 86px;
    min-width: 86px;
    width: 86px;
    height: auto;
    overflow-y: scroll;
    overflow: hidden;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    border-right: 0;
    .adm-side-bar{
      width: auto;
    }
  }

  &-customHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--adm-color-border);
    
    .adm-tabs-header {
      border-bottom: 0;
    }
    .tabs {
      flex: 1;
      :global(.adm-tabs-header) {
        border-bottom-width: 0;
      }
    }
    .dropdown {
      font-size: var(--adm-font-size-9);
      margin: auto 12px 0 12px;
    }
  }
  &-popup{
    .adm-list-item-content-extra {
      color: var(--adm-color-danger);
      font-size: var(--adm-font-size-9);
    }
    .adm-list-item-description {
      color: var(--adm-color-text-secondary);
      font-size: var(--adm-font-size-8);
  }
  }
};
.catelog-nav-container {
    width: 100%;
    padding: 0px;
    position: fixed;
    top: 42px;
    right: 0px;
}

.add_to_cart_button {
    display: block;
    position: absolute;
    right: 4px;
    bottom: 8px;
    width: 30px;
    height: 30px;
    background-position: 50%;
    background-size: 15px 15px;
}

.grid-demo-item-block2 {
    // border: solid 1px #999999;
    background: #f5f5f5;
    text-align: center;
    // color: #999999;
    height: 100%;
    font-size: 14px;
  }

@primary-color: #8F001B;@root-entry-name: default;