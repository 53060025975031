@class-prefix-order-list: ~'mall-order-list';
.@{class-prefix-order-list} {
    &-container {
        .adm-list-item-title, .adm-list-item-description {
            color: var(--adm-color-text);
        }
        .adm-list-item-content-extra { 
            color: var(--adm-color-text);
        }
        // margin-left: 96px;
        width: 100%;
        height: auto;
        padding: 0px;
        position: fixed;
        top: 0px;
        bottom: calc(51px + env(safe-area-inset-bottom));
        right: 0px;
        overflow-y: scroll;
    }

    
    
}
@primary-color: #8F001B;@root-entry-name: default;